@keyframes ldio-ljqiyj0lmf9 {
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
}

.ldio-ljqiyj0lmf9 div {
    box-sizing: border-box !important
}

.ldio-ljqiyj0lmf9 > div {
    position: absolute;
    width: 82.16px;
    height: 82.16px;
    top: 10.92px;
    left: 10.92px;
    border-radius: 50%;
    border: 5.2px solid #000;
    border-color: #1d3f72 transparent #1d3f72 transparent;
    animation: ldio-ljqiyj0lmf9 1s linear infinite;
}

.ldio-ljqiyj0lmf9 > div:nth-child(2) {
    border-color: transparent
}

.ldio-ljqiyj0lmf9 > div:nth-child(2) div {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(45deg);
}

.ldio-ljqiyj0lmf9 > div:nth-child(2) div:before, .ldio-ljqiyj0lmf9 > div:nth-child(2) div:after {
    content: "";
    display: block;
    position: absolute;
    width: 5.2px;
    height: 5.2px;
    top: -5.2px;
    left: 33.28px;
    background: #1d3f72;
    border-radius: 50%;
    box-shadow: 0 76.96000000000001px 0 0 #1d3f72;
}

.ldio-ljqiyj0lmf9 > div:nth-child(2) div:after {
    left: -5.2px;
    top: 33.28px;
    box-shadow: 76.96000000000001px 0 0 0 #1d3f72;
}

.loadingio-spinner-dual-ring-ae0s5kgxfbu {
    width: 104px;
    height: 104px;
    display: inline-block;
    overflow: hidden;
    background: none;
}

.ldio-ljqiyj0lmf9 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-ljqiyj0lmf9 div {
    box-sizing: content-box;
}

/* generated by https://loading.io/ */
