$brand-white: #fff;
$brand-color: #ec174c;
$brand-color-2: #ec135c;
$brand-color-3: #303952;
$box-shadow: 0 3px 3px -1px rgb(10 22 70 / 10%), 0 0 1px 0 rgb(10 22 70 / 6%);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "TT Hoves";
  src: local("TT Hoves Thin"), local("TT-Hoves-Thin"), url("./content/fonts/tthoves/TTHoves-Thin.woff2") format("woff2"), url("./content/fonts/tthoves/TTHoves-Thin.woff") format("woff"), url("./content/fonts/tthoves/TTHoves-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "TT Hoves";
  src: local("TT Hoves Light"), local("TT-Hoves-Light"), url("./content/fonts/tthoves/TTHoves-Light.woff2") format("woff2"), url("./content/fonts/tthoves/TTHoves-Light.woff") format("woff"), url("./content/fonts/tthoves/TTHoves-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "TT Hoves";
  src: local("TT Hoves Regular"), local("TT-Hoves-Regular"), url("./content/fonts/tthoves/TTHoves-Regular.woff2") format("woff2"), url("./content/fonts/tthoves/TTHoves-Regular.woff") format("woff"), url("./content/fonts/tthoves/TTHoves-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "TT Hoves";
  src: local("TT Hoves Medium"), local("TT-Hoves-Medium"), url("./content/fonts/tthoves/TTHoves-Medium.woff2") format("woff2"), url("./content/fonts/tthoves/TTHoves-Medium.woff") format("woff"), url("./content/fonts/tthoves/TTHoves-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "TT Hoves";
  src: local("TT Hoves DemiBold"), local("TT-Hoves-DemiBold"), url("./content/fonts/tthoves/TTHoves-DemiBold.woff2") format("woff2"), url("./content/fonts/tthoves/TTHoves-DemiBold.woff") format("woff"), url("./content/fonts/tthoves/TTHoves-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "TT Hoves";
  src: local("TT Hoves Bold"), local("TT-Hoves-Bold"), url("/content/fonts/tthoves/TTHoves-Bold.woff2") format("woff2"), url("/content/fonts/tthoves/TTHoves-Bold.woff") format("woff"), url("/content/fonts/tthoves/TTHoves-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {

  background: $brand-white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $brand-color-2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $brand-color-2;
}

html {
  scrollbar-width: thin;
}

#root {
  height: 100vh;
}

body {
  font-family: "TT Hoves" !important;
  background-color: #f5f9fc;
}

.site-wrapper {
  max-width: 1250px;
  margin: auto;
}

a {
  text-decoration: none;
}


.site-app {
  height: 100%;
  display: flex;
  flex-direction: column;

  .social-links {
    display: flex;
    align-items: center;

    .social-link {
      color: $brand-white;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      font-size: 1rem;
      border: 2px solid $brand-color;
      margin-right: .3rem;

      &:hover {
        color: $brand-white;
        border: 2px solid $brand-white;
        transform: scale(1.2) rotate(360deg);

      }
    }
  }

  .site-header {
    flex-shrink: 1;

    .header-top {
      background-color: $brand-color;

      .header-top-container {
        display: flex;
        justify-content: space-between;
        padding: .5rem 0;

        .search-link {
          border-radius: 20px;
          border: 1.7px solid $brand-white;
          color: $brand-white;
          padding: .3rem .5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 1rem;
          box-shadow: $box-shadow;

          i {
            margin-right: .3rem;
          }
        }

        //.languages {
        //
        //}
      }
    }

    .header-middle {
      background-color: $brand-white;

      .header-middle-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .site-logo-container {
          display: block;
          width: 30%;

          .site-logo {
            display: block;
            width: 100%;
          }
        }
      }


    }

    .header-bottom {
      box-shadow: 0 3px 3px -1px rgb(10 22 70 / 10%), 0 0 1px 0 rgb(10 22 70 / 6%);
      background-color: #fff;

      .navbar-brand {
        display: none;
      }

      .site-navbar {
        .nav-item {
          font-weight: 500;

          .nav-link {
            color: #303952;
            transition: all 0.2s ease-in-out;
          }

          .menu-dropdown {
            display: none;
          }

          .dropdown-menu {
            position: none; // need to fix
            overflow: hidden;

            .dropdown-item {
              &:active {
                background-color: $brand-color;
                color: $brand-white;
              }
            }
          }
        }
      }
    }
  }

  .site-main {
    flex: 1 0 auto;
    padding: 1rem 0;

    .swiper-button-next, .swiper-button-prev {
      color: $brand-color;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $brand-white;
      border-radius: 50%;
      box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
      transition: all 0.3s linear;

      &:after {
        font-size: 1.5rem !important;
        font-weight: 700;
      }

      &:hover {
        box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
      }
    }

    .section-title-container {
      position: relative;
      font-size: 1.7rem;
      width: 100%;
      display: flex;
      align-items: center;
      height: 70px;

      .section-title {
        background-color: #f5f9fc;
        position: absolute;
        z-index: 2;
        margin: 0 0 0;
        padding: .3rem 0.7rem .3rem 0;
        font-size: 1.8rem;
        text-transform: uppercase;
        .section-title-link{
          color: $brand-color-3;
        }
      }

      &::after {
        z-index: 1;
        content: "";
        width: 100%;
        height: 2px;
        background: $brand-color;
        top: 50%;
        position: absolute;
      }
    }

    .section-card {
      box-shadow: 0 3px 3px -1px rgb(10 22 70 / 10%), 0 0 1px 0 rgb(10 22 70 / 6%);
      border: none;
      border-radius: 10px;
      padding: .7rem;
      background-color: #fff;
      margin-bottom: .5rem;

      .section-card-title {
        font-size: 1.2rem;
        text-transform: uppercase;
        color: $brand-color;
        font-weight: 600;
        margin: 0;
      }

      .section-link {
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid $brand-color;
        padding: .5rem .7rem;
        transition: all .2s ease-in-out;
        color: $brand-color;
        font-size: .9rem;
        font-weight: 500;

        &:hover {

          background-color: $brand-color;
          color: $brand-white;
        }
      }
    }

    .post-item {
      border-radius: 10px;
      overflow: hidden;
      transition: all 0.3s ease;
      box-shadow: 0 3px 3px -1px rgb(10 22 70 / 10%), 0 0 1px 0 rgb(10 22 70 / 6%);
      margin-bottom: 1rem;
      padding: .7rem;
      background-color: $brand-white;

      .post-img-container {

        overflow: hidden;
        position: relative;
        border-radius: 8px;
        box-shadow: 0 3px 3px -1px rgb(10 22 70 / 10%), 0 0 1px 0 rgb(10 22 70 / 6%);

        .post-img {
          width: 100%;
          display: block;
          z-index: 1;
          transform: scale(1);
          transition: all 0.3s ease;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          background-color: $brand-color-3;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1;
        }

        .post-overlay {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .post-body {
        .post-title {
          color: $brand-color-3;
          transition: all 0.3s ease;
          font-weight: 500;
        }
      }

      &:hover {
        ::after {
          opacity: .5;
        }

        .post-overlay {
          opacity: 1;
          z-index: 2;
        }

        .post-img {
          transform: scale(1.1);

        }

        .post-title {
          color: $brand-color-3;
        }


      }
    }

    .hemophilia-case {
      width: 100%;
      height: 73px;
      background-color: $brand-color;
      color: $brand-white;
      margin-bottom: .5rem;
      border-radius: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      transition: all .2s ease-in-out;
      box-shadow: $box-shadow;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: $brand-color;
        background-color: $brand-white
      }
    }

    .load-more-button {
      border-radius: 15px;
      background-color: $brand-color;
      color: $brand-white;
      box-shadow: $box-shadow;
      padding: .5rem .8rem;
      outline: none;
      border: none;
      transition: all .15s ease-in-out;

      &:hover {
        background-color: $brand-white;
        color: $brand-color;
      }
    }

  }

  .site-footer {
    flex-shrink: 1;
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;

    .footer-top {
      background-color: $brand-white;
      padding: 1rem 0;

      .footer-top-container {
        display: flex;
        justify-content: space-between;

        .footer-top-item {
          flex: 1;
          padding: 0 1rem;
          p{
            margin-bottom:0rem;
          }
          .footer-top-item-title {
            margin-bottom: 1.5rem;

            p {
              text-transform: uppercase;
              font-size: 1.2rem;
              font-weight: 500;
              margin: 0;
            }
          }

          .footer-contactus-container {
            a {
              display: flex;
              color: #000;
              margin-bottom: .7rem;

              i {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $brand-color;
                color: $brand-white;
                margin-right: .7rem;
                border-radius: 50%;
              }
            }
          }

          .footer-our-missions-container {
            p {
              text-align: justify;
              word-spacing: -1px;
            }
          }

          .footer-top-financial-support {
            .financial-support-logo {
              width: 80%;
              display: block;
            }
          }
        }

        .top-item-1, .top-item-3{
          flex: 1.3;
        }
        .top-item-2{
          flex: 2;
        }
      }
    }

    .footer-bottom {
      background-color: $brand-color;

      .footer-bottom-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .7rem 0;

        .footer-bottom-item {
          display: flex;
          align-items: center;

          p {
            color: $brand-white;
            margin: 0;
            margin-right: .3rem;
            a {
              color: $brand-white;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .block-27 ul {
    padding: 0;
    margin: 0;
  }

  .block-27 ul li {
    display: inline-block;
    font-weight: 400;
  }

  .block-27 ul li a {
    color: $brand-color;
    text-align: center;
    display: inline-block;
    box-shadow: $box-shadow;
    padding:.3rem .5rem;
    border-radius: 50%;
    margin-right:.3rem;
    width:35px;
    height:35px;
    display:flex;
    align-items: center;
    justify-content:center;
    font-width: 500;
    background-color:$brand-white;
  }

  .block-27 ul li.active a{
    background: #4ba1fa;
    color: #fff;
    border: 1px solid transparent;
  }

}

@media screen and (max-width: 768px) {
  .site-app {
    .site-header {
      .header-top-container {
        .search-link {
          border: none !important;
          box-shadow: none !important;

          i {
            margin-right: 0px !important;
          }

          .search-text {
            display: none;
          }
        }
      }

      .header-middle {
        display: none;
      }

      .header-bottom {
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

        .navbar-brand {
          display: block;
          width: 55%;

          img {
            width: 100%;
            display: block;
          }
        }

        .site-navbar {
          .nav-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .menu-dropdown {
              display: block;

            }

            .dropdown-menu {
              position: absolute;
              width: 100%;
            }

          }
        }
      }
    }

    .site-main {
      .section-container {
        padding: .7rem;
      }

      .section-title-container {
        .section-title {
          background-color: #f5f9fc;
          position: absolute;
          z-index: 2;
          margin: 0 0 0;
          padding: .3rem 0.7rem .3rem 0;
          font-size: 1.3rem;
          text-transform: uppercase;
        }
      }

      .hemophilia-case {
        font-size: 1rem;
        height: 50px;
      }
    }

    .site-footer {
      .footer-top {
        .footer-top-container {
          flex-direction: column;

          .footer-top-item {
            margin-bottom: .7rem;

            .footer-top-financial-support {
              padding: .7rem;

              .financial-support-logo {
                width: 100%;
                display: block;
              }
            }

          }
        }
      }

      .footer-bottom {
        .footer-bottom-container {
          flex-direction: column;
          padding: .7rem 0;

          .footer-bottom-item {
            display: flex;
            align-items: center;

          }
        }
      }
    }
  }
}


@media all and (min-width: 992px) {
  .navbar .dropdown-menu-end {
    right: 0;
    left: auto;
  }
  .navbar .nav-item .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: .2s ease-in-out;
    margin-top: 0;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  }
  .navbar .nav-item .nav-link {
    padding: .7rem !important;
  }
  .navbar .nav-item:hover .nav-link {
    //color: #fff;
  }
  .navbar .dropdown-menu.fade-down {
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }
  .navbar .dropdown-menu.fade-up {
    top: 180%;
  }
  .navbar .nav-item:hover .dropdown-menu {
    transition: .3s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotateX(0deg);
  }
  .navbar .dropdown-menu {
    padding: 0;
  }
  .navbar .dropdown-menu .dropdown-item {
    padding: .7rem;
  }
}